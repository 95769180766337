import { FormField } from '@/components/form/form.types';
import { Status } from '@/types/enum.types';
import { DeckConfig } from "@/components/form/dynamic-form.types";

export interface Approval {
  statusId: number;
  statusName: string;
  assigneeName: string;
  approvalMessage: string | null;
  approvalRequestId: number;
  notificationTitle: string;
  notificationMessage: string;
  approvalRequestTypeId: number;
  approvalRequestTypeMnemonic: string;
}

export interface UpdateApprovalRequestBody {
  updateStatusId: number;
  approvalMessage: string;
  updateStatusMnemonic: string;
}

export interface ServiceProperties {
  kong_proxy_path: [string] | string;
  timeout_ms?: string;
  httpMethods?: [string];
  requestPayloadBusinessJustification?: string;
  requestPayloadSize?: string | number;
  requestPayloadUnit?: string;
  upstreamQueryString?: { key: string; value: string }[] | null;
  headers?: string[] | null;
  corsSupport?: boolean | string;
  enabled_ip_restriction_plugin: number;
  allowed_ip_list: string[];
  kong_keyauth_template_apikeyidentifier: string[];
}

export interface customHeaders {
  headers: { headerSecretName: string; headerSecretValue: string }[];
}

export interface ApiRegistration {
  isCustom: boolean;
  isControlsCompliant: boolean;
  customNotes: string | null;
  statusId: number;
  statusMnemonic: Status;
  serviceId: number;
  uniqueKey: string;
  versionId: number;
  createUserId: number;
  resourceName: string;
  environmentId: number;
  createTimestamp: string;
  deleteTimestamp: string | null;
  updateTimestamp: string;
  lastUpdateUserId: number;
  requiresApproval: boolean;
  serviceProperties: ServiceProperties;
  resourceDescription: string;
  projectEnvironmentId: number;
  requiresNotification: boolean;
  registrationVersionId: number;
  headerName?: string | null;
  headerValue?: string | null;
  headers?: { headerName: string; headerValue: string }[] | null;
  healthCheckPath?: string | null;
  healthCheckPattern?: string | null;
  timeoutMs: string;
  upstreamUrl: string;
  authenticationType: string;
  upstreamQueryString?: { key: string; value: string }[] | null;
  httpMethods?: string[] | null;
  corsSupport?: boolean;
  requestPayloadSize?: number | null;
  requestPayloadUnit?: string | null;
  requestPayloadBusinessJustification?: string | null;
  enabledIpRestrictionPlugin?: number;
  allowedIpList?: string[] | null;
  apiKeyIdentifier?: string[] | null;
  serviceNowId?: string | null;
  serviceScanConsent?: boolean;
  serviceScanApiEndpoints?: string;
}

export interface ApiRegistrationPayload {
  service?: ApiRegistrationFormData;
  deck?: DeckConfig;
  publishListingOnRegistration?: boolean
}

export type ApiRegistrationFormData = Required<
  Pick<
    ApiRegistration,
    | 'resourceName'
    | 'isCustom'
    | 'isControlsCompliant'
    | 'customNotes'
    | 'resourceDescription'
    | 'requiresApproval'
    | 'requiresNotification'
    | 'versionId'
    | 'timeoutMs'
    | 'upstreamUrl'
    | 'authenticationType'
  > & {
    serviceProperties: Required<
      Pick<
        ServiceProperties,
        'kong_proxy_path' | 'timeout_ms' | 'enabled_ip_restriction_plugin' | 'allowed_ip_list' | 'kong_keyauth_template_apikeyidentifier'
      >
    >;
    // proxyPath: Required<ServiceProperties>['kong_proxy_path'];
  }
> &
  Pick<
    ApiRegistration,
    | 'headerName'
    | 'headerValue'
    | 'headers'
    | 'healthCheckPath'
    | 'healthCheckPattern'
    | 'upstreamQueryString'
    | 'httpMethods'
    | 'corsSupport'
    | 'requestPayloadSize'
    | 'requestPayloadUnit'
    | 'requestPayloadBusinessJustification'
    | 'serviceNowId'
    | 'serviceScanConsent'
    | 'serviceScanApiEndpoints'
  >;

export enum Steps {
  One = 'one',
  Two = 'two',
  Three = 'three',
}

export const DEFAULT_TIMEOUT = '60000';
export const DEFAULT_PAYLOAD_SIZE = 128;
export const DEFAULT_PAYLOAD_UNIT = 'KB';

export enum PromotionKeys {
  approvals = 'approvals',
  headerName = 'headerName',
  headerValue = 'headerValue',
  headers = 'headers',
  upstreamUrl = 'upstream_full_uri',
  proxyPath = 'kong_proxy_path',
  authType = 'enabled_oidc_plugin_instead_of_keyauth',
  timeout = 'timeout_ms',
}

export enum FieldKeys {
  name = 'resourceName',
  description = 'resourceDescription',
  version = 'version',
  customNotes = 'customNotes',
  headerName = 'headerName',
  headerValue = 'headerValue',
  headers = 'headers',
  upstreamUrl = 'upstreamUrl',
  timeout = 'timeoutMs',
  authType = 'authenticationType',
  compliant = 'isControlsCompliant',
  approval = 'requiresApproval',
  notify = 'requiresNotification',
  smartControls = 'complianceSmartControls',
  // service properties
  // upstreamUrl = 'upstream_full_uri',
  proxyPath = 'kong_proxy_path',
  // authType = 'enabled_oidc_plugin_instead_of_keyauth',
  // timeout = 'timeout_ms',

  httpMethods = 'httpMethods',

  healthCheckEnabled = 'healthCheckEnabled',
  healthCheckPath = 'healthCheckPath',
  healthCheckPattern = 'healthCheckPattern',

  payloadSizeEnabled = 'overrideRequestPayloadSize',
  payloadSize = 'requestPayloadSize',
  payloadUnits = 'requestPayloadUnit',
  payloadJustification = 'requestPayloadBusinessJustification',

  queryParams = 'upstreamQueryString',
  corsSupport = 'corsSupport',
  allowedIPList = 'allowedIPList',
  ipRestrictionEnabled = 'ipRestrictionEnabled',
  apiKeyEnabled = 'apiKeyEnabled',
  apiKeyIdentifier = 'apiKeyIdentifier',
  serviceNowId = 'serviceNowId',
  serviceScanConsent = 'serviceScanConsent',
  serviceScanApiEndpoints = 'serviceScanApiEndpoints'
}

export type FieldMap = Record<FieldKeys, FormField<FieldKeys>>;

export type AutocompleteChoiceValue = {
  fieldKey: string;
  promise: Promise<any>;
  formatter: (value: any) => any;
  selectedKey: string;
};
