import keyBy from 'lodash/keyBy';
import {
  AutoCompleteField,
  CheckboxField,
  FormField,
  FormWizardStep,
  KeyValue,
  NumberField,
  TextField,
} from '@/components/form/form.types';
import { AUTH_SRC, kongApiKeyIdDefaultValue } from '@/constants';
import { ApiPromotionFormData } from '@/types/projects.types';
import {
  ApiRegistration,
  ApiRegistrationFormData,
  DEFAULT_PAYLOAD_SIZE,
  DEFAULT_PAYLOAD_UNIT,
  DEFAULT_TIMEOUT,
  FieldKeys,
  FieldMap,
  AutocompleteChoiceValue,
} from '@/types/registration.types';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { randId } from '@/utils/components';
import { FeatureFlagMnemonics } from '@/types/feature-flags.types';
import * as API from '@/api/projects.api';
import { Choice } from '@gsk-tech/gsk-autocomplete/gsk-autocomplete-base';

function filterFieldsByFeatureFlag(
  keys: FieldKeys[],
  featureFlagName: FeatureFlagMnemonics,
  negateFlag?: boolean,
) {
  return function (f: FormField<FieldKeys>) {
    if (keys.includes(f.key)) {
      return FeatureFlagsModule.featureFlags[featureFlagName].featureFlagEnabled && !negateFlag;
    }
    return true;
  };
}

interface GetDetailsFields {
  isEdit: boolean;
  isVersion: boolean;
  resourceNameValidator: (v: string) => Promise<string>;
  versions?: number[];
}

function getDetailsFields(args: GetDetailsFields): FormField<FieldKeys>[] {
  const { isVersion, isEdit, resourceNameValidator } = args;
  const versions = args.versions || [];
  const name: TextField<FieldKeys.name> = {
    key: FieldKeys.name,
    leadingHeader: 'Registration Details',
    type: 'text',
    label: 'Name',
    value: '',
    required: true,
    helpText: 'Alphanumeric, spaces allowed',
    attrs: {
      placeholder: 'Registration Name',
      maxLength: '50',
    },
    customValidator: resourceNameValidator,
    validation: {
      rules: 'required|max:50|regex:^[a-zA-Z0-9 ]+$',
    },
  };
  const desc: TextField<FieldKeys.description> = {
    key: FieldKeys.description,
    type: 'long-text',
    label: 'Description',
    value: '',
    required: true,
    attrs: {
      placeholder: 'Enter a short description',
      maxLength: '255',
    },
    validation: {
      rules: 'required|max:255',
    },
  };
  const version: TextField<FieldKeys.version> = {
    key: FieldKeys.version,
    type: 'text',
    label: 'Version',
    value: '',
    required: true,
    attrs: {
      placeholder: '1',
    },
    validation: {
      rules: {
        required: true,
        integer: true,

        min_value: 1,

        max_value: 999,
        excluded: versions,
      },
      customMessages: {
        excluded: 'This version already exists',
      },
    },
  };
  if (isEdit) {
    return [];
  }
  if (isVersion) {
    return [version] as FormField<FieldKeys>[];
  }
  return [name, desc, version] as FormField<FieldKeys>[];
}

export function makeFormWizardData(data: {
  isEdit: boolean;
  isVersion: boolean;
  versions?: number[];
  oauthMnemonic: string;
  proxyValidator: (v: string) => Promise<string>;
  resourceNameValidator: (v: string) => Promise<string>;
  projectAuthType?: string,
  isPromotion?: boolean,
}): FormField<FieldKeys>[] {
  const { isEdit, isVersion, isPromotion, versions, resourceNameValidator, projectAuthType } = data;
  const authTypeOptionVal = projectAuthType? AUTH_SRC[projectAuthType].readableName: AUTH_SRC[data.oauthMnemonic].readableName;
  let authTypeOptions = [authTypeOptionVal, 'API Key'];
  if(authTypeOptionVal == 'API Key'){
    authTypeOptions = ['API Key'];
  }
  return [
    ...getDetailsFields({ isVersion, isEdit, versions, resourceNameValidator }),
    {
      key: FieldKeys.upstreamUrl,
      leadingHeader: 'Upstream Configuration',
      leadingText: 'Provide connection details for the API Gateway',
      type: 'text',
      label: 'Upstream URL',
      value: '',
      required: true,
      helpText:
        'Enter the base API path from the swagger, such as https://uk1sawn0411.wmservice.corpnet1.com:90443/myapi',
      validation: {
        rules: 'kongUrl|required',
      },
    },
    {
      key: FieldKeys.httpMethods,
      type: 'checkbox',
      required: true,
      label: 'HTTP methods',
      value: [],
      options: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
      helpText: 'Select the allowed HTTP methods for this API',
      validation: {
        rules: 'arrayRequired',
        customMessages: {
          arrayRequired: 'Please select at least 1 option',
        },
      },
      attrs: {
        inline: 'true',
      },
    },
    {
      key: FieldKeys.payloadSizeEnabled,
      leadingHeader: 'Payload size',
      leadingText:
        'The default request payload size limit is 128 KB. If you need to support potentially larger requests, you will have to provide a business justification.',
      type: 'switch',
      value: false,
      label: 'Override request payload size',
      attrs: {
        primary: '1',
      },
    },
    {
      key: FieldKeys.payloadSize,
      type: 'number',
      value: DEFAULT_PAYLOAD_SIZE,
      label: 'Request payload size',
      hideIf: {
        fieldKey: FieldKeys.payloadSizeEnabled,
        fieldValue: false,
      },
      validation: {
        rules: {
          required_if: FieldKeys.payloadSizeEnabled,
          min_value: 1,
        },
      },
      layout: {
        gridColumn: '1 / 4',
      },
    },
    {
      key: FieldKeys.payloadUnits,
      type: 'select',
      label: 'Units',
      value: DEFAULT_PAYLOAD_UNIT,
      options: ['KB', 'MB'],
      layout: {
        gridColumn: '4 / 5',
      },
      hideIf: {
        fieldKey: FieldKeys.payloadSizeEnabled,
        fieldValue: false,
      },
    },
    {
      key: FieldKeys.payloadJustification,
      type: 'long-text',
      label: 'Business justification',
      value: '',
      hideIf: {
        fieldKey: FieldKeys.payloadSizeEnabled,
        fieldValue: false,
      },
      validation: {
        rules: {
          required_if: FieldKeys.payloadSizeEnabled,
        },
      },
    },
    {
      key: FieldKeys.headers,
      type: 'key-value',
      leadingHeader: 'Custom Headers (optional)',
      leadingText: 'Headers will be added to HTTP requests sent from the API Gateway to your API',
      label: 'Headers',
      keyLabel: 'Header Key',
      valueLabel: 'Header Value',
      valueType: 'password',
      keyValidation: {
        rules: {
          required: true,
          regex: /^[a-zA-Z0-9_-]+$/,
        },
        customMessages: {
          regex: 'Must match the regular expression: ^[a-zA-Z0-9_-]+$',
        },
      },
      valueValidation: {
        rules: {
          required: true,
          regex: /^[!-9;-~][ -9;-~]*$/,
        },
        customMessages: {
          regex: 'Must match the regular expression: ^[!-9;-~][ -9;-~]*$',
        },
      },
      value: [],
    },
    {
      key: FieldKeys.queryParams,
      type: 'key-value',
      leadingHeader: 'Custom query parameters (optional)',
      leadingText:
        'URL query parameters that will be added to HTTP requests sent from the API Gateway to your API',
      label: 'Query params',
      keyLabel: 'Key',
      valueLabel: 'Value',
      keyValidation: {
        rules: 'required',
      },
      valueValidation: {
        rules: 'required',
      },
      value: [],
    },
    {
      key: FieldKeys.healthCheckEnabled,
      leadingHeader: 'API health check',
      leadingText:
        'When enabled, API health checks will be performed by the Developer Portal and displayed in your project connections',
      type: 'switch',
      value: false,
      label: '',
      attrs: {
        primary: '1',
      },
    },
    {
      key: FieldKeys.healthCheckPath,
      type: 'text',
      label: 'Health check path',
      value: '',
      attrs: {
        placeholder: '/health-check',
        labeltooltiptext:
          'API Health Check will call from your own project, ' +
          'charges may be applied to your API base. ' +
          'API Health Check will not notify you in the event of an outage, ' +
          'for this we recommend the use of HP SiteScore.',
      },
      hideIf: {
        fieldKey: FieldKeys.healthCheckEnabled,
        fieldValue: false,
      },
      validation: {
        rules: {
          required_if: FieldKeys.healthCheckEnabled,
          regex: /^[/a-zA-Z0-9_-]+[a-zA-Z0-9_-]$/,
        },
        customMessages: {
          regex: 'Alphanumeric, forward slashes, underscores, dashes, no trailing slash',
        },
      },
    },
    {
      key: FieldKeys.healthCheckPattern,
      type: 'text',
      label: 'Validation regex ',
      value: '',
      attrs: {
        placeholder: 'e.g. text,^text$,...',
        labeltooltiptext:
          'When your API is hit it will return text to the Developer Portal.' +
          'Regex expression that you provide will be used to complete the API Health Check.',
      },
      hideIf: {
        fieldKey: FieldKeys.healthCheckEnabled,
        fieldValue: false,
      },
      validation: {
        rules: {
          required_if: FieldKeys.healthCheckEnabled,
        },
      },
    },
    {
      key: FieldKeys.proxyPath,
      type: 'text',
      label: 'API Gateway Proxy Path (https://api.gsk.com/<your_path>)',
      value: '',
      helpText:
        'Enter the unique path for the consumers to use your api on the gateway, such as /rnd/timecard',
      leadingHeader: 'Access Configuration',
      leadingText: 'Provide URL access between the user and the API Gateway',
      required: true,
      customValidator: data.proxyValidator,
      validation: {
        rules: 'required|regex:^[/a-zA-Z0-9_-]+[a-zA-Z0-9_-]$',
        customMessages: {
          regex: 'Alphanumeric, forward slashes, underscores, dashes, no trailing slash',
        },
      },
    },
    {
      key: FieldKeys.authType,
      type: 'radio',
      options: authTypeOptions,
      label: 'Authentication Type',
      value: AUTH_SRC[data.oauthMnemonic].readableName,
      helpText:
        'OAuth requires a two step authentication and can be used for websites to authenticate individuals. API keys are most appropriate for legacy back end communication.',
    },
    {
      key: FieldKeys.timeout,
      type: 'text',
      label: 'Timeout',
      value: DEFAULT_TIMEOUT,
      helpText:
        'Milliseconds before timing out for read and write operations. Allowed range is 1000-9,999,999.',
      required: true,
      validation: {
        rules: {
          required: true,
          regex: /^[1-9]\d{3,6}$/,
        },
        customMessages: {
          regex: 'Allowed range is 1000-9,999,999',
        },
      },
    },
    {
      key: FieldKeys.customNotes,
      helpText:
        'This form can be used to send information for custom setups, such as unauthenticated paths within your API, additional security such as IP Whitelisting, additional upstream base urls added into the same API.',
      type: 'long-text',
      label: 'Custom config details',
      leadingHeader: 'Gateway configuration (optional)',
      leadingText:
        'This form can be used to send information for custom setups, such as unauthenticated paths within your API, additional security such as IP Whitelisting, additional upstream base urls added into the same API. ',
      value: '',
    },
    {
      key: FieldKeys.corsSupport,
      leadingHeader: 'CORS Support',
      leadingText: 'When enabled, it support CORS Options to API Registration',
      type: 'switch',
      value: false,
      label: '',
      attrs: {
        primary: '1',
      },
    },
    {
      key: FieldKeys.ipRestrictionEnabled,
      leadingHeader: 'IP Restriction',
      leadingText:
        'When enabled, Whitelisted IPs will bypass restrictions by this module and will not be blocked. Only list of IPs allowed.',
      type: 'switch',
      value: false,
      label: '',
      attrs: {
        primary: '1',
      },
    },
    {
      key: FieldKeys.allowedIPList,
      type: 'input-chips',
      value: '',
      label: 'Whitelisted IPs',
      hideIf: {
        fieldKey: FieldKeys.ipRestrictionEnabled,
        fieldValue: false,
      },
      inputChipsProps: {
        unique: true,
      },
      customValidator: (v: string): Promise<string> => {
        let valid = true;
        if (v) {
          const IPRegex =
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/[0-9][0-9])?$/;
          const IPs = Array.isArray(v) ? v : v.split(',');
          if (IPs.some(ip => !ip.match(IPRegex))) {
            valid = false;
          }
          return Promise.resolve(valid ? '' : 'Please enter valid IP addresses');
        }
        return Promise.resolve('');
      },
    },
    {
      key: FieldKeys.apiKeyEnabled,
      leadingHeader: 'API Key Identifier',
      leadingText:
        'Describes an array of parameter names where the plugin will look for a key. The client must send the authentication key in one of those key names, and the plugin will try to read the credential from a header parameter with the same name. Accepts multiple header values (comma separated).',
      type: 'switch',
      value: false,
      label: '',
      attrs: {
        primary: '1',
      },
    },
    {
      key: FieldKeys.apiKeyIdentifier,
      type: 'text',
      value: kongApiKeyIdDefaultValue,
      required: true,
      hideIf: {
        fieldKey: FieldKeys.apiKeyEnabled,
        fieldValue: false,
      },
      customValidator: (v: string): Promise<string> => {
        let valid = true;
        v = v.replace(/ /g,'');
        const checkboxElem = document.getElementById(FieldKeys.apiKeyEnabled) as HTMLInputElement | null;

        if (checkboxElem?.checked && v.length > 0) {
          const regex = /^[a-zA-Z0-9_-]+$/;

          const list = v.split(',').map(item => item.trim());

          if (list.some(item => !item.match(regex))) {
            valid = false;
          }
          return Promise.resolve(
            valid
              ? ''
              : 'The key name may only contain [a-z], [A-Z], [0-9], [_] underscore, and [-] hyphen.',
          );
        }
        if (checkboxElem?.checked && v.length == 0) {
          valid = false;
        }
        return Promise.resolve(
          valid ? '' : 'This field is required.',
        );
      },
    },
    {
      key: FieldKeys.serviceNowId,
      label: 'CI ID',
      type: 'autocomplete',
      leadingHeader: 'CI ID Configuration',
      leadingText: 'Enter the Service Now Configuration Item ID (CI ID) (e.g. P012345678) for this instance of your application. If searching, type in a keyword and wait for a few seconds for some results to appear.',
      value: {
        label: '',
        value: ''
      },
      choices: [],
      async getChoices(query: string) {
        const choices = await API.getServiceNowIds(query);
        return choices.data.map(res => ({
          label: `${res.id} - ${res.name}`,
          value: res.id,
        }));
      },
      required: true,
      validation: {
        rules: 'autocompleteChoiceRequired',
      },
      attrs: {
        async: true,
        disabled: isEdit,
        placeholder: 'Type a keyword to search',
      },
    },
    {
      key: FieldKeys.notify,
      leadingHeader: 'Developer Portal options',
      leadingText:
        'As the API owner you can elect how others access this API. If you require API approval, requests will show up in your ‘Notifications’, where you can view request details such as requestor, target environment and more.',
      label: 'Notify API owner(s) when access is granted to this API',
      value: false,
      type: 'checkbox-single',
    },
    {
      key: FieldKeys.approval,
      label: 'Require API Owner approval to access this API',
      value: false,
      type: 'checkbox-single',
    },
    {
      key: FieldKeys.compliant,
      label: 'This API is SOX or GXP compliant',
      value: false,
      type: 'checkbox-single',
      leadingHeader: 'GSK API management standards',
      leadingText:
        'APIs do not need to be GxP or SOX. However, if your application is a GxP or SOX application, please indicate it here for data cataloguing purposes. ',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]
    .filter(
      filterFieldsByFeatureFlag(
        [FieldKeys.healthCheckEnabled, FieldKeys.healthCheckPattern, FieldKeys.healthCheckPath],
        FeatureFlagMnemonics.HEALTHCHECK,
      ) as never,
    )

    .filter(
      filterFieldsByFeatureFlag(
        [
          FieldKeys.payloadSizeEnabled,
          FieldKeys.payloadSize,
          FieldKeys.payloadUnits,
          FieldKeys.payloadJustification,
          FieldKeys.queryParams,
          FieldKeys.httpMethods,
        ],
        FeatureFlagMnemonics.REGISTRATIONNEWFIELDS,
      ) as never,
    )
    .filter(
      filterFieldsByFeatureFlag(
        [FieldKeys.serviceNowId],
        FeatureFlagMnemonics.CIIDPERSERVICE,
      ) as never,
    );
}

export async function populateAutocompleteChoices(
  formFields: FormField[],
  values: AutocompleteChoiceValue[],
) {
  for(const value of values) {
    const field = formFields.find(ff => ff.key === value.fieldKey);
    if (field) {
      const choicesResult = await value.promise;
      (field as AutoCompleteField).choices = choicesResult.map(value.formatter);
      field.value = (field as AutoCompleteField).choices.find((choice) => choice.value === value.selectedKey) as Choice;
    }
  }
  return formFields;
}

/**
 * just ensures there's a leading slash, for now...
 */
export function normalizeUrlPath(path: string, dedupeSlashes = false) {
  const p = path.startsWith('/') ? path : '/' + path;
  if (dedupeSlashes) {
    return removeConsecutiveSlashes(p);
  }
  return p;
}

function removeConsecutiveSlashes(path: string) {
  return path.replace(/\/+/g, '/');
}

export function resolveProxyPath(path: string | [string]) {
  if (typeof path === 'string') {
    return path;
  }
  return path[0];
}

export const converters = {
  toServer: {
    [FieldKeys.authType](fields: FieldMap): string {
      const valueReadableName = getValue(fields[FieldKeys.authType]);
      const valueAuthSrcKey = Object.keys(AUTH_SRC).find(
        currentKey => AUTH_SRC[currentKey].readableName === valueReadableName,
      );
      return valueAuthSrcKey ? AUTH_SRC[valueAuthSrcKey].apiRegistrationEnum : 'APIKEY';
    },
    [FieldKeys.proxyPath](fields: FieldMap): string {
      return normalizeUrlPath(getValue(fields[FieldKeys.proxyPath]), true);
    },
    [FieldKeys.healthCheckPath](fields: FieldMap): string {
      return normalizeUrlPath(getValue(fields[FieldKeys.healthCheckPath]));
    },
    [FieldKeys.queryParams](fields: FieldMap): ApiRegistration['upstreamQueryString'] {
      const field = fields[FieldKeys.queryParams];
      if (!field) {
        return null;
      }
      return (field.value as KeyValue[]).map(({ key, value }) => {
        return { key, value };
      });
    },
    [FieldKeys.headers](fields: FieldMap): ApiRegistration['headers'] {
      const field = fields[FieldKeys.headers];
      if (!field) {
        return [];
      }
      return (field.value as KeyValue[]).map(({ key, value }) => {
        return { headerName: key, headerValue: value };
      });
    },
  },
  toClient: {
    [FieldKeys.authType](projectEnv: any, reg: ApiRegistration): string {
      const valueAuthSrcKey = Object.keys(AUTH_SRC).find(
        currentKey => AUTH_SRC[currentKey].apiRegistrationEnum === reg.authenticationType,
      );
      return valueAuthSrcKey ? AUTH_SRC[valueAuthSrcKey].readableName : 'API Key';
    },
    [FieldKeys.proxyPath](reg: ApiRegistration): string {
      const path = reg.serviceProperties[FieldKeys.proxyPath];
      return normalizeUrlPath(resolveProxyPath(path), true);
    },
    [FieldKeys.headers](reg: ApiRegistration): KeyValue[] {
      const params = reg.headers;
      if (params && Array.isArray(params)) {
        return params.map(kv => {
          return { key: kv.headerName, value: kv.headerValue, id: randId() };
        });
      }
      return [];
    },
    [FieldKeys.httpMethods](reg: ApiRegistration): string[] {
      const httpsMethods = reg.httpMethods ? reg.httpMethods : reg.serviceProperties.httpMethods;
      return !httpsMethods?.length ? ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'] : httpsMethods;
    },
    [FieldKeys.queryParams](reg: ApiRegistration): KeyValue[] {
      const params = reg.serviceProperties.upstreamQueryString;
      if (params && Array.isArray(params)) {
        return params.map(kv => {
          return { ...kv, id: randId() };
        });
      }
      return [];
    },
  },
};

function takeFields(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keys: [FieldKeys, any][],
  proxyValidator: (v: string) => Promise<string>,
  resourceNameValidator: (v: string) => Promise<string>,
  projectEnv: any,
): FormField[] {
  const fields = keyBy(
    makeFormWizardData({
      isEdit: false,
      isVersion: false,
      oauthMnemonic: getAuthMnemonicFromKeys(keys),
      proxyValidator,
      resourceNameValidator,
      projectAuthType: projectEnv.auth.OAuth.oauthType,
    }),
    field => field.key,
  );
  return keys
    .filter(([key]) => fields[key])
    .map(([key, value]) => {
      const field = fields[key];
      field.value = value ?? '';
      return field;
    });
}

function getAuthMnemonicFromKeys(keys: [FieldKeys, any][]): string {
  const authKey = keys.find(item => item[0] === FieldKeys.authType);
  if (!authKey) {
    return '';
  }
  const authConf = Object.keys(AUTH_SRC).find(item => AUTH_SRC[item].readableName === authKey[1]);
  return authConf ? AUTH_SRC[authConf].mnemonic : '';
}

const smartControlsOpts: CheckboxField = {
  type: 'checkbox',
  label: 'Smart Controls',
  value: [],
  key: 'complianceSmartControls',
  validation: {
    rules: 'length:2|required',
    customMessages: {
      length: 'WAST and Smart Controls are required when promoting to Production',
      required: 'WAST and Smart Controls are required when promoting to Production',
    },
  },
  options: [
    {
      label: 'Smart Controls assessment has been performed on this API',
      value: 'hasSmartControls',
    },
    {
      label: 'WAST has been performed on this API',
      value: 'hasWAST',
    },
  ],
  required: true,
};

export function makePromotionFormWizardStep(
  projectEnv: any,
  service: ApiRegistration,
  envName: string,
  proxyValidator: (v: string) => Promise<string>,
  resourceNameValidator: (v: string) => Promise<string>,
  isDev: boolean,
): FormWizardStep<string, { serviceId: number }> {
  const k = FieldKeys;
  const healthOn = FeatureFlagsModule.featureFlags.HEALTHCHECK.featureFlagEnabled;
  const newFieldsOn = FeatureFlagsModule.featureFlags.REGISTRATIONNEWFIELDS.featureFlagEnabled;
  const multiheaders = true;
  const nullFilter = (val: unknown): val is [FieldKeys, never] => val !== null;
  const newField = (f: unknown) => (newFieldsOn ? f : null);
  const healthField = (f: unknown) => (healthOn ? f : null);
  const multipleHeaderField = (f: unknown) => (multiheaders ? f : null);
  const apiKeyEnabled = Array.isArray(service.apiKeyIdentifier)
    && service.apiKeyIdentifier.toString() !== kongApiKeyIdDefaultValue;
  const form = {
    key: service.serviceId.toString(),
    heading: `Promote ${service.resourceName} v${service.versionId} to ${envName}`,
    data: {
      serviceId: service.serviceId,
    },
    nav: {
      text: `${service.resourceName} v${service.versionId}`,
      key: service.serviceId.toString(),
    },
    fields: takeFields(
      [
        [k.upstreamUrl, ''],
        newField([k.httpMethods, converters.toClient[k.httpMethods](service)]),
        newField([
          k.payloadSizeEnabled,
          !!service.serviceProperties.requestPayloadBusinessJustification,
        ]),
        newField([
          k.payloadSize,
          service.serviceProperties.requestPayloadSize || DEFAULT_PAYLOAD_SIZE,
        ]),
        newField([
          k.payloadUnits,
          service.serviceProperties.requestPayloadUnit || DEFAULT_PAYLOAD_UNIT,
        ]),
        newField([
          k.payloadJustification,
          service.serviceProperties.requestPayloadBusinessJustification,
        ]),
        multipleHeaderField([k.headers, []]),
        newField([k.queryParams, converters.toClient[k.queryParams](service) ?? []]),
        healthField([k.healthCheckEnabled, !!service.healthCheckPath]),
        healthField([k.healthCheckPath, service.healthCheckPath]),
        healthField([k.healthCheckPattern, service.healthCheckPattern]),
        [k.proxyPath, converters.toClient[k.proxyPath](service)],
        [k.authType, converters.toClient[k.authType](projectEnv, service)],
        [k.timeout, service.serviceProperties.timeout_ms ?? DEFAULT_TIMEOUT],
        [k.customNotes, service.customNotes ?? ''],
        newField([k.corsSupport, Boolean(service.serviceProperties.corsSupport)]),
        [k.ipRestrictionEnabled, service.enabledIpRestrictionPlugin === 1],
        [k.allowedIPList, service.allowedIpList?.join(',') || ''],
        [k.apiKeyEnabled, apiKeyEnabled],
        [k.apiKeyIdentifier, service.apiKeyIdentifier ? service.apiKeyIdentifier?.join(',') : ''],
        [k.serviceNowId, service.serviceNowId ?? ''],
        [k.notify, service.requiresNotification ?? false],
        [k.approval, service.requiresApproval ?? false],
        [k.compliant, service.isControlsCompliant ?? false],
      ].filter(nullFilter),
      proxyValidator,
      resourceNameValidator,
      projectEnv,
    ).map(field => {
      if (field.hide) {
        field.hide = false;
      }
      return field;
    }),
  };

  if (!isDev) {
    form.fields.push(smartControlsOpts);
  }

  return form;
}

export function getValue(field: FormField): string {
  return field?.value?.toString() ?? '';
}

export function getParsedValue(field: FormField, delimiter = ','): string[] {
  return (
    field?.value
      ?.toString()
      ?.split(delimiter)
      ?.filter(v => v) ?? []
  );
}

/**
 *  will return null for non-existent or non-completed optional fields
 */
export function getOptionalValue(field: FormField): string | null {
  return field?.value?.toString() || null;
}

export function getBooleanValue(field: FormField): boolean {
  return field?.value ? !!field.value : false;
}

export function getArrayValue<T>(field: FormField): T[] {
  return (field?.value as unknown as T[]) ?? [];
}

export function getNumberValue(field: NumberField, optional = false): number | null {
  if (optional) {
    return field.value ?? null;
  }
  return field.value ?? 0;
}

export function getSingleSelectStringValue(field: FormField): string | null {
  if (!field) {
    return null;
  }
  const value = (field.value) as Choice;
  if (value && 'value' in value) {
    return value.value || null;
  }
  return null;
}

/**
 * versions and updates don't need name/desc
 * but sending them empty won't hurt and makes this simpler
 */
export function prepareFormData(
  fields: FieldMap,
  reg?: { resourceName: string; resourceDescription: string },
): ApiRegistrationFormData {
  const k = FieldKeys;
  const resourceName = reg ? reg.resourceName : getValue(fields.resourceName);
  const resourceDescription = reg ? reg.resourceDescription : getValue(fields.resourceDescription);
  const payloadEnabled = getBooleanValue(fields.overrideRequestPayloadSize);
  // const requiresNotification = approvals.includes('requiresApproval');
  const healthCheckEnabled = getBooleanValue(fields.healthCheckEnabled);
  return {
    resourceName:resourceName.trim(),
    resourceDescription:resourceDescription.trim(),
    requiresNotification: getBooleanValue(fields.requiresNotification),
    requiresApproval: getBooleanValue(fields.requiresApproval),
    isControlsCompliant: getBooleanValue(fields.isControlsCompliant),
    upstreamUrl: getValue(fields.upstreamUrl),
    timeoutMs: getValue(fields.timeoutMs),
    authenticationType: converters.toServer[k.authType](fields),
    serviceProperties: {
      timeout_ms: getValue(fields.timeoutMs),
      [k.proxyPath]: converters.toServer[k.proxyPath](fields),
      enabled_ip_restriction_plugin: +getBooleanValue(fields.ipRestrictionEnabled),
      allowed_ip_list: getBooleanValue(fields.ipRestrictionEnabled)
        ? getParsedValue(fields.allowedIPList)
        : [],
      kong_keyauth_template_apikeyidentifier: getBooleanValue(fields.apiKeyEnabled)
      ? getParsedValue(fields.apiKeyIdentifier).map(item => item.trim())
      : [kongApiKeyIdDefaultValue],
    },
    versionId: +getValue(fields.version),
    isCustom: getBooleanValue(fields.customNotes),
    customNotes: getValue(fields.customNotes),
    headerName: getOptionalValue(fields.headerName),
    headerValue: getOptionalValue(fields.headerValue),
    healthCheckPath: healthCheckEnabled ? getOptionalValue(fields.healthCheckPath) : null,
    healthCheckPattern: healthCheckEnabled ? getOptionalValue(fields.healthCheckPattern) : null,
    upstreamQueryString: converters.toServer[k.queryParams](fields),
    headers: converters.toServer[k.headers](fields),
    httpMethods: getArrayValue<string>(fields.httpMethods),
    corsSupport: getBooleanValue(fields.corsSupport),
    requestPayloadSize: payloadEnabled
      ? getNumberValue(fields.requestPayloadSize as NumberField, true)
      : null,
    requestPayloadUnit: payloadEnabled ? getValue(fields.requestPayloadUnit) : null,
    requestPayloadBusinessJustification: payloadEnabled
      ? getValue(fields.requestPayloadBusinessJustification)
      : null,
    serviceNowId: getSingleSelectStringValue(fields.serviceNowId),
    serviceScanConsent: getBooleanValue(fields.serviceScanConsent),
    serviceScanApiEndpoints: getValue(fields.serviceScanApiEndpoints)
  };
}

export function preparePromotionFormData(
  fields: FieldMap,
  serviceId: number,
): ApiPromotionFormData {
  const smartControls = fields.complianceSmartControls
    ? (fields.complianceSmartControls as CheckboxField).value
    : undefined;
  const k = FieldKeys;
  const payloadEnabled = getBooleanValue(fields.overrideRequestPayloadSize);
  const healthCheckEnabled = getBooleanValue(fields.healthCheckEnabled);
  return {
    serviceId,
    requiresNotification: getBooleanValue(fields.requiresNotification),
    requiresApproval: getBooleanValue(fields.requiresApproval),
    isControlsCompliant: getBooleanValue(fields.isControlsCompliant),
    smartControlsCompleted: smartControls ? smartControls.includes('hasSmartControls') : false,
    wastTestComplete: smartControls ? smartControls.includes('hasWAST') : false,
    authenticationType: converters.toServer[k.authType](fields),
    proxyPath: converters.toServer[k.proxyPath](fields),
    upstreamUrl: getValue(fields.upstreamUrl),
    timeoutMs: getValue(fields.timeoutMs),
    isCustom: getBooleanValue(fields.customNotes),
    customNotes: getValue(fields.customNotes),
    headerName: getOptionalValue(fields.headerName),
    headerValue: getOptionalValue(fields.headerValue),
    headers: converters.toServer[k.headers](fields),
    healthCheckPath: healthCheckEnabled ? getOptionalValue(fields.healthCheckPath) : null,
    healthCheckPattern: healthCheckEnabled ? getOptionalValue(fields.healthCheckPattern) : null,
    upstreamQueryString: converters.toServer[k.queryParams](fields),
    httpMethods: getArrayValue<string>(fields.httpMethods),
    corsSupport: getBooleanValue(fields.corsSupport),
    requestPayloadSize: payloadEnabled
      ? getNumberValue(fields.requestPayloadSize as NumberField)
      : null,
    requestPayloadUnit: payloadEnabled ? getValue(fields.requestPayloadUnit) : null,
    requestPayloadBusinessJustification: payloadEnabled
      ? getValue(fields.requestPayloadBusinessJustification)
      : null,
    enabledIpRestrictionPlugin: +getBooleanValue(fields.ipRestrictionEnabled),
    allowedIpList: getBooleanValue(fields.ipRestrictionEnabled)
      ? getParsedValue(fields.allowedIPList)
      : [],
    apiKeyIdentifier: getBooleanValue(fields.apiKeyEnabled)
      ? getParsedValue(fields.apiKeyIdentifier).map(item => item.trim())
      : [kongApiKeyIdDefaultValue],
    serviceNowId: getSingleSelectStringValue(fields.serviceNowId),
    serviceScanConsent: getBooleanValue(fields.serviceScanConsent),
    serviceScanApiEndpoints: getValue(fields.serviceScanApiEndpoints)
  };
}

export function convertServiceDetailsToFormData(
  service: ApiRegistration,
  update: Partial<Omit<ApiRegistration, 'serviceProperties'>> = {},
): ApiRegistrationFormData {
  const sp: ApiRegistration['serviceProperties'] & { timeout_ms: string } = {
    ...service.serviceProperties,
    timeout_ms: service.timeoutMs || DEFAULT_TIMEOUT,
  };
  return { ...service, serviceProperties: sp, ...update, timeoutMs: DEFAULT_TIMEOUT};
}
